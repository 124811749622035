import React from "react";

export default function About() {
  var yearsOfExperience = new Date().getFullYear() - 2009;
  return (
    <section id="about">
      <h1 id="main-title">Hello. My name is Samantha Raut.</h1>
      <p>I am a web developer based in Rhode Island with over {yearsOfExperience} years of experience. I am passionate about user experience, clean code, and accessibility. I have built sites using traditional HTML & CSS, Sass, Bootstrap, Drupal, Twig, and more.</p>
      <div className="profile-pic">
        <img src="./images/samantha-raut-profile.png" alt="Samantha Raut Profile"></img>
      </div>
    </section>
  );
}