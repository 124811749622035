import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const recaptcha = useRef();
  const form = useRef();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [records, setRecords] = useState([]);

  // Success message
  const [status, setStatus] = useState(undefined);

  const sendEmail = (e) => {
    e.preventDefault();
    setRecords([...records, formValues]);
    setFormValues({
      name: "",
      email: "",
      message: ""
    });
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      // Make form submission
      emailjs.sendForm('service_fwyqzvk', 'template_rqqm54o', form.current, 'pXwm8UwMdte8XxEwj')
      .then((result) => {
        setStatus({ type: 'success' });
        
      //Reset form
      setRecords([...records, formValues]);
      setFormValues({
        name: "",
        email: "",
        message: ""
      });
      }, (error) => {
        console.log(error.text);
        setStatus({ type: 'error', error })
      });
    }
  };

  return (
    <section id="contact">
      <h2>Contact</h2>
      <p>Want to work together? Get in touch.</p>
      <p className="required"><span className="red">*</span> Asterisk indicates required field.</p>

      {status?.type === 'success' && <div className="success-message"><p>Thank you for your message. I will be in touch shortly.</p></div>}
    
      <form id="contact-form" ref={form} onSubmit={sendEmail} method='POST'>
        <label htmlFor="name">Your name: <span className="red">*</span></label>
        <input 
          id="name"
          type="text" 
          name="user_name" 
          maxLength={50}
          required
          value={formValues.name}
          onChange={(e) =>
            setFormValues({ ...formValues, name: e.target.value })
          }
        />
        <label htmlFor="email">Email: <span className="red">*</span></label>
        <input 
          id="email"
          type="email" 
          name="user_email"
          required
          value={formValues.email}
          onChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value })
          }
        />
        <label htmlFor="message">Your Message: <span className="red">*</span></label>
        <textarea 
          id="message"
          name="message" 
          required
          value={formValues.message}
          onChange={(e) =>
            setFormValues({ ...formValues, message: e.target.value })
          }
        />
        <ReCAPTCHA
          id="recaptcha"
          name="recaptcha"
          ref={recaptcha}
          sitekey="6LccoDcpAAAAACZ6thQI9b1NGgD6wrIFChUp_ZmJ" // prod
          //sitekey="6LfgpjcpAAAAAJclhD1sduXs1fQtq0ePNmCdxOnH" //localhost
        />
        <div id="html_element"></div>
        <button type="submit">Send message</button>
      </form>
    </section>
  )
}