import '../App.scss';
import React from 'react';
import Navbar from './Navbar';
import About from "./About";
import Projects from './Projects';
import Skills from './Skills';
import Contact from './Contact';
import Footer from './Footer';

export default function Portfolio() {
  return (
    <div className="App">
      <Navbar />
      <main>     
        <About />
        <Projects />
        <Skills />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}