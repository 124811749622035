import { React } from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";


export default function Footer() {
  return (
    <footer>
      <p>Copyright &copy; 2009 to <script>document.write(new Date().getFullYear())</script>2023 Samantha Raut.</p>

      <ul>
        <li>
          <a href="https://www.linkedin.com/in/samantha-raut-29265418/" target="_blank" aria-label="Find me on Linkedin"><FaLinkedin /></a>
        </li>
        <li>
          <a href="https://twitter.com/SamanthaRaut" target="_blank" aria-label="Follow my on X (former Twitter)"><FaXTwitter  /></a>
        </li>
        <li>
          <a href="https://www.instagram.com/samantharaut/" target="_blank" aria-label="Follow me on Instagram"><FaInstagram /></a>
        </li>
        <li>
          <a href="mailto:samantha.raut@gmail.com" aria-label="Send me an email"><IoMdMail /></a>
        </li>
      </ul>
    </footer>
  );
}