import React from "react";
import { skills } from "../data";
import { FiTool } from "react-icons/fi";

export default function Skills() {
  return (
    <section id="skills">
      <div>
        <h2>
          Skills &amp; Technologies
        </h2>
        <p>
          I enjoy building my skillset and learning new things. Here is a list of technologies I've worked with.
        </p>
      </div>
      {skills.map((skill) => (
      <div key={skill.id} className="skills-container">
        
        <ul className="skills-list">
          {skill.skillNames?.map(skillName =>
            <li key={skillName} className="skills-list-item">
              <FiTool />
              {skillName}
            </li>
          )}
        </ul>
        
      </div>
      ))}
    </section>
  );
}