import React from "react";
import { projects } from "../data";
import { FaArrowRightLong } from "react-icons/fa6";

export default function Projects() {
  return (
    <section id="projects">
      <h2>Projects</h2>
      <ul className="projects-container">
        {projects.map((project) => (          
          <li key={project.id} className="project">
            <div className="project-img">
              <img alt={project.title + " screenshot"} src={project.image}/>
            </div>
            
            <div className="project-desc">
              <h3 className="">
                {project.title}
              </h3>
              <p className="">{project.description}</p>
              <h4>Development tools</h4>
              <ul className="devtools">
                {project.tools?.map(tool =>
                  <li key={tool}>
                    {tool}
                  </li>  
                )}
              </ul>
              <a href={project.link} target="_blank">View {project.title} project <FaArrowRightLong /></a>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}