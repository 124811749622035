export const projectSkills = [
  "Drupal",
  "Twig HTML",
  "SASS/CSS",
  "JavaScript",
  "jQuery",
  "PHP"
];

export const projects = [
    {
      id: 'accordant',
      title: "accordant",
      description: 
        "Nurse care management website built for CVS Health.",
      tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
      image: "/images/project-accordant.png",
      link: "https://www.accordant.com",
    },
    {
      id: "addventures",
      title: "(add)ventures",
      description: 
        "Corporate website updated and maintained.",
      tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
      image: "/images/project-addventures.png",
      link: "https://addventures.com",
    },
    {
      id: "cvs-suppliers",
      title: "CVS Suppliers",
      description: 
        "Suppliers website built for CVS Health.",
      tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
      image: "/images/project-cvs-suppliers.png",
      link: "https://cvssuppliers.com",
    },
    {
      id: "insulet",
      title: "Insulet",
      description:
        "Worked with the Insulet team to export a responsive and accessible menu for their corporate website.",
        tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
        image: "/images/project-insulet.png",
      link: "https://www.insulet.com/",
    },
    {
      id: "cvs-aco",
      title: "CVS ACO",
      description:
        "Accountable Care Organization website built for CVS Health.",
        tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
        image: "/images/project-cvs-aco.png",
      link: "https://www.cvsaco.com",
    },
    {
      id: "cvs-medicare-fall-forum",
      title: "CVS Medicare Fall Forum",
      description:
        "Medicare Fall Forum website built for CVS Health.",
        tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
      image: "/images/project-cvs-medicare-fall-forum.png",
      link: "https://www.cvsmedicarefallforum.com",
    },
    {
      id: "cvs-caremark-connections",
      title: "CVS Caremark Connections",
      description:
        "Website built for CVS Caremark.",
        tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
        image: "/images/project-cvs-caremark-connections.png",
      link: "http://caremarkconnections.cvshealth.com",
    },
    {
      id: "encompass-fertility",
      title: "Encompass Fertility",
      description:
        "Fertility website built for CVS Health.",
      tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
      image: "/images/project-encompass-fertility.png",
      link: "https://www.encompassfertility.com/",
    },
    {
      id: "coramhc",
      title: "Coram HC",
      description: 
        "Website for Patients and Caregivers, Providers, and Manufacturers built for CVS Health.",
        tools: ["Drupal", "Twig HTML", "SASS/CSS", "JavaScript/jQuery", "PHP"],
      image: "/images/project-coramhc.png",
      link: "https://www.coramhc.com/",
    },
  ];

  export const skills = [{
    id: "skills",
    skillNames: [    
    "HTML/HTML5",
    "Twig HTML",
    "CSS",
    "SASS",
    "Drupal",
    "JavaScript",
    "jQuery",
    "Bootstrap",
    "PHP",
    "Contentful",
    "React.js",
    "GraphQL",
    "Adobe XD",
    "Sketch"]
  }];