import { React, useState } from "react";
import { FiMenu } from "react-icons/fi";

export default function Navbar({setMenuStatus }) {

  // Track if mobile menu is open or closed
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header>
      <div id="skip-link-container">
        <a href="#main-title" className="visually-hidden focusable">Skip to main content</a>
      </div>
      <div className="container-nav">
        <a href="/" className="logo-name">
          <div className="logo">
            <img src="./images/owl-profile-circle.png" alt="Samantha Raut Logo"></img>
          </div>
          <div className="name">
            <span className="firstname">Samantha Raut</span><br />
            <span className="occupation">Web developer</span>
          </div>
        </a>
        <nav className="desktop">
          <ul>
            <li>
              <a href="#about" className="">About</a>
            </li>
            <li>
              <a href="#projects" className="">Projects</a>
            </li>
            <li>
              <a href="#skills" className="">Skills</a>
            </li>
            <li>
              <a href="#contact" className="">Contact</a>
            </li>
          </ul>
        </nav>

        <button className="open-mobile-menu" onClick={toggle}>
          <div className={`${isOpen ? 'close' : 'hamburger'}`}>
            <span class="hidden" aria-hidden="true">
              Mobile menu
            </span>
          </div>
        </button>
      </div>

      <div className="mobile-menu">
        <nav className={`mobile ${isOpen ? 'show' : ''}`}>
          <ul>
            <li>
              <a href="#about" onClick={toggle}>About</a>
            </li>
            <li>
              <a href="#projects" onClick={toggle}>Projects</a>
            </li>
            <li>
              <a href="#skills" onClick={toggle}>Skills</a>
            </li>
            <li>
              <a href="#contact" onClick={toggle}>Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>  
  );
}