import React from "react";
import './App.scss';
import Navbar from './components/Navbar';
import About from "./components/About";
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio';

export default function NotFound() {
  return (
    <div className="App">
      <Navbar />
      <main>  
        <section id="error-page">
          <h1 id="main-title">404 page not found</h1>
          <p>Sorry we couldn't find that page, but we looked! Please check your URL or return to the <a href="/">home page</a>.</p>

          <div className="profile-pic">
            <img src="./images/404-error-cats.png" alt="404 cats not found"></img>
          </div>
        </section>   
      </main>
      <Footer />
    </div>
  );
}