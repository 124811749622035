import './App.scss';
import React from 'react';
import Portfolio from './components/Portfolio';
import NotFound from './NotFound';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Portfolio />} />
        <Route path="/projects" element={<Portfolio />} />
        <Route path="/contact" element={<Portfolio />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
